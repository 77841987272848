<template>
  <div class="flex flex-col h-full">
    <a
      href="#"
      @click.prevent="
        deactivated = !value
        $emit('input', deactivated)
      "
      class="px-2 py-1 border mb-4 rounded self-start hover:text-white hover:border-white"
      :class="!deactivated ? 'text-red-500 hover:bg-red-500' : 'text-blue-500 hover:bg-blue-500'"
      v-show="title"
      >{{ title }}</a
    >
    <a
      v-if="
        addBtn &&
          permission &&
          can(
            permission.module,
            permission.subModule,
            permission.childModule,
            permission.operation,
            permission.options
          )
      "
      href="#"
      @click.prevent="callback && callback()"
      class="text-white px-2 py-1 rounded self-start bg-gray-500 hover:bg-gray-600 flex items-center space-x-2 mb-2"
    >
      <span v-html="icon"></span>
      <span>{{ addBtn }}</span></a
    >

    <nav
      v-if="_.first(categories)"
      class="flex overflow-x-scroll space-x-2 text-base mb-2 h-10"
    >
      <button
        v-for="(category, index) in categories"
        :key="`category-${index}`"
        :tabindex="index"
        class="border rounded px-4 py-1 text-xs"
        :class="currentCategory === category && 'bg-blue-dark text-white'"
        @click.prevent="() => categoryHandler(category)"
      >
        {{ category }}
      </button>
    </nav>

    <h1 v-show="label" class="mb-4 text-base">{{ label }}</h1>
    <div class="h-full rounded border border-gray-200">
      <div class="align-middle inline-block min-w-full h-full">
        <div class="border-b border-gray-200 sm:rounded h-full">
          <table v-if="rows" class="min-w-full divide-y divide-gray-200 overflow-y-auto">
            <thead class="bg-gray-50">
              <tr>
                <th
                  v-for="(column, index) in columns"
                  scope="col"
                  :class="
                    `px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-${column.headerAlign}`
                  "
                  :key="`column-${index}`"
                  :width="`${column.width}%`"
                >
                  {{ column.label }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                v-for="(row, row_index) in filteredRows"
                :key="`row-${row_index}`"
                class="border-b border-gray-200"
                :class="{ 'bg-gray-50': row_index % 2 }"
              >
                <td
                  v-for="(column, column_index) in columns"
                  :key="`data-${row_index}-${column_index}`"
                  class="py-2 px-2 text-left font-bold"
                >
                  <template v-if="column.type === 'callback'">
                    <a
                      href="#"
                      @click.prevent="column.callback(row)"
                      class="text-blue-900 cursor-pointer flex"
                    >
                      <div
                        v-if="row['archived']"
                        class="rounded-full h-2 w-2 bg-gray-200 m-1 ml-0"
                      ></div>
                      <div v-else class="rounded-full h-2 w-2 m-1 ml-0"></div>
                      <span v-for="(field, index) in column.field" :key="`${field}-${index}`">
                        {{ _.get(row, field) }}
                      </span>
                    </a>
                  </template>
                  <template v-else-if="column.type === 'link'">
                    <template v-if="row.type === 'pdf'">
                      <a
                        href="#"
                        @click.prevent="
                          $bus.$emit(
                            column.event,
                            `/${resource || row.linkTo || column.linkTo}/${_.get(
                              row,
                              column.link
                            )}/`
                          )
                        "
                        class="text-blue-900 cursor-pointer flex"
                      >
                        <div
                          v-if="row['archived']"
                          class="rounded-full h-2 w-2 bg-gray-200 m-1 ml-0"
                        ></div>
                        <div v-else-if="title" class="rounded-full h-2 w-2 m-1 ml-0"></div>
                        <template v-if="column.field">
                          <template v-for="(field, index) in column.field">
                            <span :key="`${field}-${index}`" class="mr-1">
                              {{ _.get(row, field) }}
                            </span>
                          </template>
                        </template>
                      </a>
                    </template>
                    <template v-else>
                      <router-link
                        :to="
                          `/${resource || row.linkTo || column.linkTo}/${_.get(row, column.link)}`
                        "
                        class="text-blue-900 cursor-pointer flex"
                      >
                        <div
                          v-if="row['archived']"
                          class="rounded-full h-2 w-2 bg-gray-200 m-1 ml-0"
                        ></div>
                        <div v-else-if="title" class="rounded-full h-2 w-2 m-1 ml-0"></div>
                        <template v-if="column.field">
                          <template v-for="(field, index) in column.field">
                            <span :key="`${field}-${index}`" class="mr-1">
                              {{ _.get(row, field) }}
                            </span>
                          </template>
                        </template>
                      </router-link>
                    </template>
                  </template>
                  <template v-else-if="column.type === 'emit'">
                    <a
                      @click.prevent="$emit('selected', row)"
                      class="text-blue-900 cursor-pointer flex"
                    >
                      <template v-for="(field, index) in column.field">
                        <div :key="`${field}-${index}`" class="flex">
                          <div
                            v-if="row['archived']"
                            class="rounded-full h-2 w-2 bg-gray-200 m-1 ml-0"
                          ></div>
                          <div v-else class="rounded-full h-2 w-2 m-1 ml-0"></div>
                          <span>
                            {{ _.get(row, field) }}
                          </span>
                        </div>
                      </template>
                    </a>
                  </template>
                  <template v-else-if="column.type === 'actions' || column.type === 'checkbox'">
                    <div class="flex justify-between">
                      <template v-for="(action, action_index) in column.actions">
                        <i
                          :class="`${action.icon} cursor-pointer`"
                          @click="
                            () => {
                              action.callback(row.id)
                              updateDataTable()
                            }
                          "
                          :key="`action-${action_index}`"
                        ></i>
                      </template>
                      <template>
                        <div
                          v-if="
                            actions &&
                              permission &&
                              can(
                                permission.module,
                                permission.subModule,
                                permission.childModule,
                                permission.operation,
                                permission.options
                              )
                          "
                          class="flex justify-between"
                        >
                          <div class="relative inline-block text-left self-end my-1 mr-2">
                            <span class="relative rounded z-10"
                              ><button
                                class="inline-flex justify-center w-full px-2 text-xs leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded hover:text-gray-500 focus:outline-none active:bg-gray-50 active:text-gray-800"
                                type="button"
                                @click.prevent="() => closed(row_index)"
                              >
                                <span>Aktion </span>
                                <svg
                                  class="w-5 h-5 ml-2 -mr-1"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg></button
                            ></span>
                            <div
                              class="relative transition-all duration-300 transform origin-top-right -translate-y-2 scale-95 z-50 inset-0"
                              :class="current === row_index ? '' : 'opacity-0 invisible '"
                            >
                              <div
                                class="absolute right-0 w-36 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded outline-none"
                              >
                                <a
                                  v-if="actions.list.includes('edit')"
                                  href="#"
                                  class="text-gray-700 flex w-full px-2 py-1 text-sm leading-5 text-left hover:bg-gray-200"
                                  @click.prevent="() => emitAction({ type: 'edit', payload: row })"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5 mr-2"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                                    />
                                  </svg>
                                  <span>Bearbeiten</span></a
                                >
                                <a
                                  v-if="actions.list.includes('delete')"
                                  href="#"
                                  class="text-gray-700 flex w-full px-2 py-1 text-sm leading-5 text-left hover:bg-gray-200"
                                  @click.prevent="
                                    () =>
                                      emitAction({
                                        type: 'delete',
                                        payload: row,
                                      })
                                  "
                                  ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5 mr-2"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clip-rule="evenodd"
                                    /></svg
                                  ><span>Entfernen</span></a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="flex m-1">
                          <input
                            :type="column.type"
                            @change="toggleSelection($event, row.id)"
                            class="h-4 w-4 text-indigo-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                        </div> -->
                      </template>
                    </div>
                  </template>
                  <template v-else-if="column.type === 'button'">
                    <div class="flex justify-center">
                      <template v-if="row['email']">
                        <div v-if="!row['user']">
                          <button
                            @click.prevent="column.callback(row.email)"
                            class="border rounded px-2 py-1 hover:bg-gray-100 hover:text-gray-700"
                          >
                            {{ column['deactivate'] }}
                          </button>
                        </div>
                        <span class="border rounded px-2 py-1 font-normal" v-else>{{
                          column['activate']
                        }}</span>
                      </template>
                    </div>
                  </template>
                  <template v-else-if="column.bool === 'bool'">
                    <span
                      v-for="(field, index) in column.field"
                      :key="`${field}-${index}`"
                      class="px-2 py-1 rounded text-white"
                      :class="_.get(row, field) ? 'bg-gray-400' : 'bg-green-500'"
                    >
                      {{ _.get(row, field) ? 'Inaktiv' : 'Aktiv' }}
                    </span>
                  </template>
                  <template v-else-if="column.type === 'status'">
                    <span
                      v-for="(field, index) in column.field"
                      :key="`status-${field}-${index}`"
                      class="py-1 rounded text-green-500"
                    >
                      {{ statusTranslate(_.get(row, field)) }}
                    </span>
                  </template>
                  <template v-else-if="column.type === 'image'">
                    <div class="flex relative">
                      <span v-for="(field, index) in column.field" :key="`image-${field}-${index}`">
                        <span v-if="_.has(row, field)">
                          <template v-if="_.get(row, field)">
                            <img
                              :src="_.get(row, field)"
                              :class="
                                `rounded-full w-6 h-6 object-cover absolute -top-3 left-${index *
                                  5}`
                              "
                              :alt="_.get(row, `prepared_by[${index}].personal.name`)"
                              :title="_.get(row, `prepared_by[${index}].personal.name`)"
                              v-tooltip="_.get(row, `prepared_by[${index}].personal.name`)"
                            />
                          </template>
                          <template v-else-if="_.get(row, `prepared_by[${index}].personal.name`)">
                            <span :class="`rounded-full w-6 h-6 absolute -top-3 left-${index * 5}`">
                              <avatar
                                :username="_.get(row, `prepared_by[${index}].personal.name`)"
                                :size="24"
                                v-tooltip="_.get(row, `prepared_by[${index}].personal.name`)"
                              />
                            </span>
                          </template>
                          <span v-else>-</span>
                        </span>
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <span
                      v-for="(field, index) in column.field"
                      :key="`${field}-${index}`"
                      :class="column.type === 'date' && row.expired"
                    >
                      {{
                        (field.includes('date') || column.type === 'date') && _.get(row, field)
                          ? moment(_.get(row, field), 'YYYY-MM-DD').format('DD.MM.YYYY')
                          : _.get(row, field)
                          ? column.strlen
                            ? `${_.get(row, field).substr(0, column.strlen)}${column['postfix'] &&
                                column['postfix']}`
                            : `${_.get(row, field)}${column['postfix'] && column['postfix']}`
                          : '-'
                      }}
                    </span>
                  </template>
                </td>
              </tr>

              <!-- More people... -->
            </tbody>
          </table>

          <div v-else class="text-center p-2">
            Keine Daten verfügbar.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'data-repeater-with-categorie-filter',
  props: [
    'label',
    'title',
    'icon',
    'actions',
    'addBtn',
    'columns',
    'rows',
    'searchIn',
    'show',
    'resource',
    'callback',
    'value',
    'permission',
  ],
  components: {},
  data() {
    return {
      selections: [],
      current: null,
      deactivated: false,
      currentCategory: '',
    }
  },
  created() {
    this.updateQuery(null)
    this.updateFilters(null)
  },
  mounted() {
    this.init()
  },
  computed: {
    categories() {
      // return _.uniq([
      //   'Categorie 1',
      //   'Categorie 1',
      //   'Categorie 2',
      //   'Categorie 3',
      //   'Categorie 4',
      //   'Categorie 5',
      // ])
      return _.uniq(_.map(this.rows, row => row.category))
    },
    filteredRows() {
      if (this.categories) return this.rows.filter(row => row.category === this.currentCategory)
      else return this.rows
    },
  },
  methods: {
    init() {
      this.deactivated = this.value
    },
    updateDataTable() {
      setTimeout(() => {
        this.$emit('reload')
      }, 500)
    },
    toggleSelection(e, id) {
      if (e.target.checked) {
        this.selections.push(id)
        this.selections = _.uniq(this.selections)
      } else this.selections = _.xor(this.selections, [id])

      this.$forceUpdate()
    },
    categoryHandler(category) {
      this.currentCategory = category !== this.currentCategory ? category : ''
    },
    closed(id) {
      this.current = this.current === id ? null : id
    },
    away() {
      this.current = null
    },
    emitAction(state) {
      this.current = null
      this.actions.callback(state)
    },
  },
}
</script>

<style>
.vorbereitung-erledigt {
  background-color: #10b981;
}
.vorbereitung {
  background-color: #ffa200;
}
.abgeschlossen {
  background-color: #ff0000;
}
</style>
